.container3 {
  display: flex;
  flex-direction: column;
  width: 26vw;
  height: 43vh;
  border: 1px solid black;
  margin-left: 32vw;
  background-color: white;
  padding: 3vw 3vh;
  margin-top: 15vh;
  border-radius: 15px;
  border-style: none;
  box-shadow: 3px 3px 3px gray;
  text-decoration: none;
}

.title {
  margin: 5px;
}

.savepost {
  margin: 5px;
  padding: 1vw 1vh;
  background-color: rgb(109, 231, 231);
  color: black;
  border-radius: 10px;
  border-style: none;
  font-weight: 700;
  font-size: 1em;
}

.textarea {
  margin-top: 1vh;
}
