body {
  margin: 0;
  padding: 0;
}

.home_header {
  width: 100%;
  height: 10%;
  background-color: #10ad98;
  color: black;
}

.home__heading {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.home__navlist {
  display: flex;
  flex-direction: row;
  gap: 1vw;
  margin-left: 71%;
  margin-top: -5vh;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
}

.home__navlist li {
  list-style: none;
}

.useremail {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-image: url("./profile.png");
  background-color: blue;
}
