body {
  background-color: rgba(178, 236, 222, 0.7);
}

.container {
  width: 26vw;
  height: 43vh;
  border: 1px solid black;
  margin-left: 32vw;
  background-color: white;
  padding: 3vw 3vh;
  margin-top: 15vh;
  border-radius: 15px;
  border-style: none;
  box-shadow: 3px 3px 3px gray;
}

.login_title {
  margin-left: 10px;
}

.login-body {
  margin-top: 10px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.input {
  width: 21vw;
  padding: 8px 8px;
  font-size: 16px;
  border-radius: 6px;
}

.label {
  margin: 4px;
}

.checkbox {
  margin-top: 22px;
  margin-left: -23vw;
}

.remember {
  margin-left: 30px;
  margin-top: -20px;
  margin-bottom: 20px;
}

/* .login-btn {
  padding: 5px 5px;
  width: 22vw;
  background-color: #1098ad;
  color: white;
  font-weight: 300;
  border-radius: 5px;
  border-style: none;
  margin-left: 10px;
} */

.forgot {
  margin-left: 18vw;
  font-size: 12px;
}

.login-footer {
  margin-top: 0.2vh;
  margin-left: 5vw;
}

.emailerror {
  font-size: 0.7em;
  color: red;
}

.passerror {
  font-size: 0.7em;
  color: red;
}

.singup-btn {
  background-color: red;
  color: white;
  border-radius: 5px;
  padding: 3px 3px;
  border-style: none;
}
